import React, { useContext } from 'react';
import classes from './menu.module.scss';
import { Link } from 'react-router-dom';
import { Context } from '../../../Context';

const Menu = ({ title, page }) => {
  const { state } = useContext(Context);
  const { sidebarClose } = state;
  return (
    <div className={classes.container}>
      <Link to={page} onClick={sidebarClose}>
        {title}
      </Link>
    </div>
  );
};

export default Menu;
