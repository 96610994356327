import React from 'react';
import classes from './footer.module.scss';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Instagram from '../../Branding/Social/Instagram';
import Facebook from '../../Branding/Social/Facebook';

const Footer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.title}>Need Help?</div>
        <Link to='/faqs' className={classes.menu}>
          FAQs
        </Link>
        <Link to='/contact' className={classes.menu}>
          Contact Us
        </Link>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.title}>Connect with us</div>
        <div className={classes.icon}>
          <Facebook>
            <FaFacebookSquare />
          </Facebook>
          <Instagram>
            <FaInstagram />
          </Instagram>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.title}>Terms of service</div>
        <Link to='/shipping-returns' className={classes.menu}>
          Shipping and Returns
        </Link>
        <Link to='/terms-policies' className={classes.menu}>
          Terms and Policies
        </Link>
      </div>
    </div>
  );
};

export default Footer;
