import React, { useContext } from 'react';
import { FaBars } from 'react-icons/fa';
import classes from './burgerIcon.module.scss';
import { Context } from '../../Context';

const BurgerIcon = () => {
  const { state } = useContext(Context);
  const { sidebarOpen } = state;

  return <FaBars className={classes.icon} onClick={sidebarOpen} />;
};

export default BurgerIcon;
