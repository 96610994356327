import React from 'react';
import classes from './contact.module.scss';
import TextInput from '../../components/UI/TextInput/TextInput';
import TextArea from '../../components/UI/TextArea/TextArea';
import Button from '../../components/UI/Button/Button';
import { useFormik } from 'formik';
import { contactSchema } from '../../functions/contactSchema';
import Title from '../../components/UI/Title/Title';
import axios from 'axios';
import { API_URL } from '../../config/apiendpoint';

const Contact = props => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      comments: '',
    },
    validationSchema: contactSchema,
    onSubmit: async values => {
      try {
        const response = await axios.post(`${API_URL}/contact`, {
          message: values,
        });
        console.log(response.data.success);
        if (response.data.success) props.history.push('/contact/thanks');
        else props.history.push('/contact');
      } catch (error) {
        console.log(error.message);
        props.history.push('/contact');
      }
    },
  });
  return (
    <div className={classes.container}>
      <Title content="Want to get in touch?" />
      <h2>Contact Us</h2>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          type="text"
          text="first name"
          id="firstName"
          {...formik.getFieldProps('firstName')}
        />
        {formik.touched.firstName && formik.errors.firstName ? (
          <div className={classes.error}>{formik.errors.firstName}</div>
        ) : null}
        <TextInput
          type="text"
          text="last name"
          id="lastName"
          {...formik.getFieldProps('lastName')}
        />
        {formik.touched.lastName && formik.errors.lastName ? (
          <div className={classes.error}>{formik.errors.lastName}</div>
        ) : null}
        <TextInput
          type="email"
          text="email"
          id="email"
          {...formik.getFieldProps('email')}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className={classes.error}>{formik.errors.email}</div>
        ) : null}
        <TextInput
          type="text"
          text="mobile number"
          id="phone"
          {...formik.getFieldProps('phone')}
        />
        {formik.touched.phone && formik.errors.phone ? (
          <div className={classes.error}>{formik.errors.phone}</div>
        ) : null}
        <TextArea
          type="text"
          text="comments"
          id="comments"
          {...formik.getFieldProps('comments')}
        />
        {formik.touched.comments && formik.errors.comments ? (
          <div className={classes.error}>{formik.errors.comments}</div>
        ) : null}
        <div className={classes.btn}>
          <Button type="submit" text="Submit" />
        </div>
      </form>
    </div>
  );
};

export default Contact;
