import React from "react";
import classes from "./forms.module.scss";
import Title from "../UI/Title/Title";
import Flower from "../../components/Branding/Flower/Flower";

export const Forms = props => {
  return (
    <div className={classes.container}>
      <Title content={props.title} />
      <Flower />
      {props.children}
    </div>
  );
};
