import React, { useContext } from 'react';
import classes from './cart.module.scss';
import CartProduct from '../../components/Cart/Product/CartProduct';
import { Context } from '../../Context';
import Header from '../../components/Cart/Header/Header';
import CheckOut from '../../components/Cart/CheckOut/CheckOut';
import Title from '../../components/UI/Title/Title';

const Cart = () => {
  const { state } = useContext(Context);
  const { addQuantity, minusQuantity, cart, removeProduct, price } = state;

  return (
    <div className={classes.container}>
      <Title content="My Cart" />
      <div className={classes.body}>
        <Header />
        <div className={classes.wrapper}>
          {cart.length > 0 ? (
            cart.map(item => (
              <CartProduct
                info={item}
                add={addQuantity}
                minus={minusQuantity}
                remove={removeProduct}
                key={item.id}
              />
            ))
          ) : (
            <p className={classes.empty}>No items in the cart.</p>
          )}
        </div>
        <CheckOut price={price} />
      </div>
    </div>
  );
};

export default Cart;
