import React, { useContext } from 'react';
import classes from './wishlist.module.scss';
import Title from '../../components/UI/Title/Title';
import Product from '../../components/Wishlist/Product';
import { Context } from '../../Context';
import Header from '../../components/Wishlist/Header/Header';

const Wishlist = () => {
  const { state } = useContext(Context);
  const { wishlist, addToCart, removeWishlist } = state;
  return (
    <div className={classes.container}>
      <Title content='My Flawless Wishlist' />
      <div className={classes.body}>
        <Header />
        <div className={classes.wrapper}>
          {wishlist.length > 0 ? (
            wishlist.map(item => (
              <Product
                addToCart={addToCart}
                info={item}
                key={item.id}
                removeWishlist={removeWishlist}
              />
            ))
          ) : (
            <p className={classes.empty}>No items in the Wishlist.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
