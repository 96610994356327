import React from 'react';
import classes from './logo.module.scss';
import { logo, logoText } from '../../../images/media';
import { Link } from 'react-router-dom';
import useWindowScrollPosition from '@rehooks/window-scroll-position';

const Logo = () => {
  let position = useWindowScrollPosition();
  return (
    <div className={classes.container}>
      <Link to="/">
        <img
          src={position.y > 0 ? logoText : logo}
          alt="logo"
          className={classes.logo}
        />
      </Link>
    </div>
  );
};

export default Logo;
