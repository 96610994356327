import React from "react";
import classes from "./shopNow.module.scss";
import Products from "../../components/Products/Products";
import Title from "../../components/UI/Title/Title";
import Button from "../../components/UI/Button/Button";
import ShopInsta from "../../components/Branding/ShopInsta/ShopInsta";

const ShopNow = () => {
  return (
    <div className={classes.container}>
      <Title content="Our Products" />
      <div className={classes.body}>
        <Products />
      </div>
      <div className={classes.btn}>
        <Button text="See more" />
      </div>
      <ShopInsta />
    </div>
  );
};

export default ShopNow;
