import React, { Fragment, useEffect, useContext } from 'react';
import Carousel from '../../components/Carousel/Carousel';
import Title from '../../components/UI/Title/Title';
import Products from '../../components/Products/Products';
import ShopInsta from '../../components/Branding/ShopInsta/ShopInsta';
import { Context } from '../../Context';

const Home = () => {
  const { state } = useContext(Context);
  const { checkAuth, updateCart } = state;

  useEffect(() => {
    checkAuth();
    updateCart();
  }, [checkAuth, updateCart]);

  return (
    <Fragment>
      <Carousel />
      <Title content="Latest Products" />
      <Products />
      <ShopInsta />
    </Fragment>
  );
};

export default Home;
