import React, { useContext } from 'react';
import Header from '../../components/UI/Header/Header';
import Navbar from '../../components/UI/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Context } from '../../Context';

const Navigation = () => {
  const { state } = useContext(Context);
  const { isSidebar, login, cart } = state;

  return (
    <>
      {isSidebar ? <Sidebar /> : null}
      <Header login={login} cart={cart} />
      <Navbar />
    </>
  );
};

export default Navigation;
