import React from 'react';
import classes from './orderList.module.scss';
import { Link } from 'react-router-dom';

export const OrderList = ({ id, price, date }) => {
  return (
    <div className={classes.container}>
      <p>{date}</p>
      <Link to={`/order/${id}`}>
        <p>{id}</p>
      </Link>
      <p>AU ${price}</p>
    </div>
  );
};
