import React from 'react';
import classes from './remove.module.scss';
import { FaTrash } from 'react-icons/fa';

const Remove = ({ remove, info }) => (
  <div className={classes.wrapper} onClick={() => remove(info.id)}>
    <FaTrash />
  </div>
);

export default Remove;
