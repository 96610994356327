import React from 'react';
import classes from './product.module.scss';
import { FaShoppingCart, FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import formatPrice from '../../../functions/formatPrice';
import { getProductImg } from '../../../images/media';

const Product = ({ name, price, addToCart, addToWishlist, id }) => {
  return (
    <div className={classes.container}>
      <img src={getProductImg(id)} alt="thumbnail" />
      <div className={classes.overlay}>
        <Link to={`/products/${id}`}>
          <div className={classes.header}>
            <div className={classes.head}>
              <p>{name}</p>
            </div>
            <div className={classes.price}>
              <p>${formatPrice(price)}</p>
            </div>
          </div>
        </Link>
        <div className={classes.cart}>
          <div className={classes.icon} onClick={() => addToCart(id)}>
            <FaShoppingCart />
            <span>ADD TO CART</span>
          </div>
          <div className={classes.icon} onClick={() => addToWishlist(id)}>
            <FaHeart />
            <span>Wishlist</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
