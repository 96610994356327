import React from 'react';
import Label from '../Label/Label';
import classes from './textInput.module.scss';

const TextInput = ({ text, type, id, ...rest }) => {
  return (
    <div className={classes.wrapper}>
      <Label text={text} id={id} />
      <div className={classes.inputBox}>
        <input
          type={type}
          id={id}
          {...rest}
          placeholder={`enter your ${text}`}
        />
      </div>
    </div>
  );
};

export default TextInput;
