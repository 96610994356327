import React from "react";
import ScrollToTop from "react-scroll-up";
import { FaChevronUp } from "react-icons/fa";
import classes from "./scrollUp.module.scss";

const ScrollUp = () => {
  return (
    <ScrollToTop showUnder={150}>
      <div className={classes.wrapper}>
        <FaChevronUp color="white" size="1.2rem" />
      </div>
    </ScrollToTop>
  );
};

export default ScrollUp;
