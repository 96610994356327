const formatPrice = number => {
  const price = number.toString().split('.');
  if (price.length === 1) {
    return `${price[0]}.00`;
  }
  if (price.length === 2 && price[1].length === 1) {
    return `${price[0]}.${price[1]}0`;
  }

  return +number;
};

export default formatPrice;
