import React from 'react';
import Title from '../../UI/Title/Title';
import Instagram from '../Social/Instagram';
import classes from './shopInsta.module.scss';
import { shopInsta, shopInstaMobile } from '../../../images/media';

const ShopInsta = () => {
  return (
    <div className={classes.container}>
      <Title content="Shop our Insta!" />
      <div className={classes.wrapper}>
        <Instagram>
          <img src={shopInsta} alt="products" className={classes.desktopOnly} />
          <img
            src={shopInstaMobile}
            alt="products"
            className={classes.mobileOnly}
          />
        </Instagram>
      </div>
    </div>
  );
};

export default ShopInsta;
