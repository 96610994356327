import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import classes from './carousel.module.scss';
import { header, headerMobile } from '../../images/media';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className={classes.container}>
      <Carousel
        autoPlay
        showThumbs={false}
        showStatus={false}
        className={classes.carousel}
      >
        <div className={classes.wrapper}>
          <div className={classes.thumbnail}>
            <img src={header} alt="sale" />
          </div>
          <div className={classes.thumbnailMobile}>
            <img src={headerMobile} alt="sale" />
          </div>
        </div>
      </Carousel>
      <div className={classes.highlights}>
        <div className={classes.text}>Just In!</div>
        <Link to="/products" className={classes.btn}>
          Shop the collection
        </Link>
      </div>
    </div>
  );
};
