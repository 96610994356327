import * as Yup from "yup";

export const contactSchema = Yup.object({
  firstName: Yup.string()
    .max(32, "Must be 32 characters or less")
    .matches(/^[A-Za-z ]+$/, {
      message: "Only contains Letters"
    })
    .required("Required"),
  lastName: Yup.string()
    .max(32, "Must be 32 characters or less")
    .matches(/^[A-Za-z ]+$/, {
      message: "Only contains Letters"
    })
    .required("Required"),
  email: Yup.string()
    .email("Invalid email addresss")
    .required("Required"),
  phone: Yup.string()
    .matches(/^[0-9 +-]+$/, {
      message: "Only contains numbers"
    })
    .required("Required"),
  comments: Yup.string().required("Required")
});
