import * as Yup from 'yup';

export const registerSchema = Yup.object({
  firstName: Yup.string()
    .max(32, 'Must be 32 characters or less')
    .matches(/^[A-Za-z ]+$/, {
      message: 'Only contains Letters',
    })
    .required('Required'),
  lastName: Yup.string()
    .max(32, 'Must be 32 characters or less')
    .matches(/^[A-Za-z ]+$/, {
      message: 'Only contains Letters',
    })
    .required('Required'),
  birthday: Yup.string().required('Required'),
  mobile: Yup.string().matches(/^[0-9 +-]+$/, {
    message: 'Only contains numbers',
  }),
  email: Yup.string()
    .email('Invalid email addresss')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Must be between 8 - 32 characters')
    .max(32, 'Must be between 8 - 32 characters')
    .required('Required'),
});
