import React from 'react';
import classes from './textArea.module.scss';
import Label from '../Label/Label';

const TextArea = ({ type, text, id, ...rest }) => {
  return (
    <div className={classes.wrapper}>
      <Label text={text} id={id} />
      <div className={classes.textArea}>
        <textarea
          type={type}
          id={id}
          {...rest}
          placeholder={`enter your ${text}`}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
