import React, { useContext, useEffect } from 'react';
import classes from './Product.module.scss';
import { Context } from '../../Context';
import formatPrice from '../../functions/formatPrice';
import { getProductImg } from '../../images/media';

const Product = props => {
  const { state } = useContext(Context);
  const { addToCart, addToWishlist, currentProduct, getCurrentProduct } = state;
  const item = currentProduct;

  useEffect(() => {
    getCurrentProduct(props.match.params.id);
  }, [props.match.params.id, getCurrentProduct]);

  return (
    <div className={classes.wrapper}>
      {currentProduct ? (
        <div className={classes.product}>
          <div className={classes.thumbnail}>
            <img src={getProductImg(item.id)} alt="product" />
          </div>
          <div className={classes.description}>
            <h2>{item.name}</h2>
            <p>{item.description}</p>
            <div className={classes.price}>
              Price: $ {formatPrice(item.price)}
            </div>
            <div className={classes.btnContainer}>
              <div className={classes.btn} onClick={() => addToCart(item.id)}>
                Add To Cart
              </div>
              <div
                className={classes.btn}
                onClick={() => addToWishlist(item.id)}
              >
                Add To Wishlist
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Product;
