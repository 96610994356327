import React, { Fragment, useContext } from 'react';
import classes from './modal.module.scss';
import { Context } from '../../../Context';
import Product from './Product/Product';

const Modal = () => {
  const { state } = useContext(Context);
  const { isModal, removeModal, isProductModal } = state;

  return isModal ? (
    <Fragment>
      <div className={classes.wrapper} onClick={removeModal} />

      {isProductModal ? (
        <div className={classes.modal}>
          <Product />
        </div>
      ) : null}
    </Fragment>
  ) : null;
};

export default Modal;
