import React from 'react';
import classes from './questions.module.scss';

export const Questions = ({ title, questions }) => {
  return (
    <div className={classes.container}>
      <h2>{title}</h2>
      {questions.map((question, index) => (
        <div className={classes.questions} key={index}>
          <div className={classes.question}>
            <div className={classes.title}>Question:</div>
            <p>{question['question']}</p>
          </div>
          <div className={classes.answer}>
            <div className={classes.title}>Answer:</div>
            <p>{question['answer']}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
