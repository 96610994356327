import React from 'react';
import Title from '../../components/UI/Title/Title';
import classes from './aboutUs.module.scss';
import Flower from '../../components/Branding/Flower/Flower';

const AboutUs = () => {
  return (
    <div className={classes.container}>
      <Title content='Who are we?' />
      <Flower />
      <div className={classes.story}>
        <p>
          Flawless Jewelry, as an affiliated enterprise of Outback Ventures, is
          a jewelry brand built for you to celebrate your wins in life. To us,
          jewelry is not a celestial treat anymore, but an accessible reward for
          you.
        </p>
        <p>
          We recognized that more and more young women are starting to buy
          jewelry for themselves, hence our decision to build this brand to
          offer you the opportunity to treat yourself for all your efforts and
          success in your student, professional or personal life. We want our
          customers to feel inspired and empowered by our jewelry pieces.
        </p>
        <p>
          As our passionate team consists of young students, we too want to find
          the jewelry pieces that will make us feel confident and look our best
          to get out there everyday and do better. Through sharing our best
          products with you, we hope that our jewelry can help you embellish
          your uniqueness and awesomeness.
        </p>
        <p>We value every moment in life, just like you.</p>
      </div>
    </div>
  );
};

export default AboutUs;
