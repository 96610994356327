import React from "react";
import classes from "./label.module.scss";

const Label = ({ text, id }) => {
  return (
    <div className={classes.wrapper}>
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

export default Label;
