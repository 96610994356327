import React, { useContext } from 'react';
import classes from './sidebar.module.scss';
import { FaTimes, FaUserAlt } from 'react-icons/fa';
import Menu from './Menu/Menu';
import { Context } from '../../Context';
import { Link } from 'react-router-dom';
import { flowerLogo, logoText } from '../../images/media';

const Sidebar = () => {
  const { state } = useContext(Context);
  const { sidebarClose } = state;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.icon}>
          <FaTimes onClick={sidebarClose} />
        </div>
        <div className={classes.logo}>
          <Link to="/" onClick={sidebarClose}>
            <img src={logoText} alt="logo" />
          </Link>
        </div>
        <div className={classes.icon}>
          <Link to="/login" onClick={sidebarClose}>
            <FaUserAlt />
          </Link>
        </div>
      </div>
      <div className={classes.body}>
        <Menu title="Shop Now" page="/products" />
        <Menu title="About Us" page="/about-company" />
        <Menu title="Contact Us" page="/contact" />
        <Menu title="Wishlist" page="/wishlist" />
      </div>
      <div className={classes.flower}>
        <img src={flowerLogo} alt="logo" />
      </div>
    </div>
  );
};

export default Sidebar;
