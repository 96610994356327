import { useContext, useEffect } from 'react';
import { Context } from '../../Context';

const Logout = props => {
  const { state } = useContext(Context);
  const { logout } = state;

  useEffect(() => {
    localStorage.clear();
    logout();
    props.history.push('/');
  });

  return null;
};

export default Logout;
