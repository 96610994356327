import React, { useContext, useState } from 'react';
import classes from './login.module.scss';
import { useFormik } from 'formik';
import { loginSchema } from '../../functions/loginSchema';
import TextInput from '../../components/UI/TextInput/TextInput';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';
import { Forms } from '../../components/Forms/Forms';
import axios from 'axios';
import { Context } from '../../Context';
import { API_URL } from '../../config/apiendpoint';
import { Error } from '../../components/Error/Error';

export const Login = props => {
  const [error, setError] = useState(null);
  const { state } = useContext(Context);
  const { updateContent } = state;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async values => {
      try {
        const response = await axios.post(`${API_URL}/user/login`, {
          username: values.email,
          password: values.password,
        });
        localStorage.setItem(
          'flawless-token',
          response.headers['x-auth-token'],
        );
        updateContent();
        props.history.push('/');
      } catch (error) {
        error.response
          ? setError(error.response.data.message)
          : setError(error.message);
        dismissError(5000);
      }
    },
  });

  const dismissError = async time => {
    await setTimeout(() => {
      setError(null);
    }, time);
  };

  return (
    <div className={classes.container}>
      <Forms title="login">
        <form onSubmit={formik.handleSubmit}>
          {error ? (
            <Error error={error} onClick={() => dismissError(0)} />
          ) : null}
          <TextInput
            type="email"
            text="email address"
            id="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={classes.error}>{formik.errors.email}</div>
          ) : null}
          <TextInput
            type="password"
            text="password"
            id="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className={classes.error}>{formik.errors.password}</div>
          ) : null}
          <div className={classes.btn}>
            <Button type="submit" text="Submit" />
            <div className={classes.signup}>
              Not registered yet? <Link to="/register">Sign Up now</Link>
            </div>
          </div>
        </form>
      </Forms>
    </div>
  );
};
