import React from 'react';
import classes from './orderStatus.module.scss';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';

export const OrderStatus = ({ m1, m2 }) => {
  return (
    <div className={classes.container}>
      <h1>{m1}</h1>
      <h2>{m2}</h2>
      <div className={classes.message}>
        <p>Please visit My Account page to view your orders</p>
        <Link to="/my-account">
          <Button text="My Orders" />
        </Link>
      </div>
    </div>
  );
};
