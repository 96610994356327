import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context } from '../Context';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(Context);
  const { checkLogin } = state;
  return (
    <Route
      {...rest}
      render={props =>
        checkLogin() ? <Redirect to="/my-account" /> : <Component {...props} />
      }
    />
  );
};
