import React from 'react';
import classes from './error.module.scss';
import { FaTimes } from 'react-icons/fa';

export const Error = ({ error, onClick }) => {
  return (
    <div className={classes.formError}>
      <p>{error}</p>
      <FaTimes onClick={onClick} className={classes.icon} />
    </div>
  );
};
