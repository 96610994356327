import React, { useContext } from 'react';
import classes from './checkout.module.scss';
import { Link } from 'react-router-dom';
import Pricing from '../Pricing/Pricing';
import { Context } from '../../../Context';
import Button from '../../UI/Button/Button';

const CheckOut = ({ price }) => {
  const { state } = useContext(Context);
  const { cart } = state;
  return (
    <div className={classes.wrapper}>
      <Pricing price={price.total} title="Total" />
      <div className={classes.taxComment}>Inclusive of GST & Shipping</div>
      <div className={classes.btnBox}>
        <Link to="/products">
          <Button text="continue shopping" />
        </Link>
        <Link
          to="/checkout"
          style={cart.length < 1 ? { pointerEvents: 'none' } : null}
        >
          <Button text="Check out" abc={false} />
        </Link>
      </div>
    </div>
  );
};

export default CheckOut;
