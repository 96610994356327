import React from 'react';
import classes from './pricing.module.scss';
import formatPrice from '../../../functions/formatPrice';

const Pricing = ({ price, title }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <div className={classes.price}>AU ${formatPrice(price)}</div>
    </div>
  );
};

export default Pricing;
