import React, { useEffect, useContext } from 'react';
import classes from './order.module.scss';
import { Context } from '../../Context';
import { Product } from './Product/Product';

export const Order = props => {
  const { state } = useContext(Context);
  const { getCurrentOrder, currentOrder } = state;

  let totalAmount, orderDate, shippingAddress;

  if (currentOrder) {
    const { address, amount, date } = currentOrder;
    totalAmount = amount.total;
    orderDate = date.split('T')[0];
    shippingAddress = (
      <>
        <p>{address.line1}</p>
        <p>{`${address.city} ${address.state}`}</p>
        <p>{`${address.postal_code} ${address.country_code}`}</p>
      </>
    );
  }

  useEffect(() => {
    getCurrentOrder(props.match.params.id);
  }, [props.match.params.id, getCurrentOrder]);

  console.log(currentOrder);

  return (
    <div className={classes.container}>
      <div className={classes.orderNumber}>
        <p>Order Number : {props.match.params.id}</p>
      </div>
      <div className={classes.orderInfo}>
        <div className={classes.orderDetails}>
          <p>
            Order Date : <span>{orderDate}</span>
          </p>
          <p>
            Total Amount : <span>AU ${totalAmount}</span>
          </p>
        </div>
        <div className={classes.orderAddress}>
          <p className={classes.title}>Shipping Address</p>
          {shippingAddress}
        </div>
      </div>
      <div className={classes.productList}>
        <div className={classes.title}>
          <p className={classes.product}>Product</p>
          <p className={classes.price}>Price</p>
          <p className={classes.quantity}>Quantity</p>
        </div>
        {currentOrder
          ? currentOrder.items.map(item => (
              <Product
                name={item.name}
                id={item.id}
                price={item.price}
                quantity={item.quantity}
                key={item.id}
              />
            ))
          : null}
      </div>
    </div>
  );
};
