import React from 'react';
import classes from './link.module.scss';

const Facebook = props => {
  return (
    <div className={classes.wrapper}>
      <a
        href='https://www.facebook.com/flawlessauz/'
        rel='noopener noreferrer'
        target='_blank'
      >
        {props.children}
      </a>
    </div>
  );
};

export default Facebook;
