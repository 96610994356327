import React from 'react';
import classes from './name.module.scss';
import { Link } from 'react-router-dom';
import { getProductImg } from '../../../../images/media';

const Product = ({ info }) => {
  return (
    <div className={classes.wrapper}>
      <Link to={`/products/${info.id}`}>
        <div className={classes.image}>
          <img src={getProductImg(info.id)} alt="product" />
        </div>
        <div className={classes.name}>
          <p>{info.name.split('-').join(' ')}</p>
        </div>
      </Link>
    </div>
  );
};

export default Product;
