import React from 'react';
import classes from './product.module.scss';
import { getProductImg } from '../../../images/media';

export const Product = ({ name, price, quantity, id }) => {
  return (
    <div className={classes.container}>
      <div className={classes.product}>
        <div className={classes.image}>
          <img src={getProductImg(id)} alt="product" />
        </div>
        <div className={classes.name}>{name}</div>
      </div>
      <div className={classes.price}>AU ${price}</div>
      <div className={classes.quantity}>{quantity}</div>
    </div>
  );
};
