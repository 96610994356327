const IMAGE_URL = 'https://flawlesstrading.com.au/images/branding';
const PRODUCT_IMAGE_URL = 'https://flawlesstrading.com.au/images/products';

export const flowerLogo = `${IMAGE_URL}/flowerLogo.png`;
export const header = `${IMAGE_URL}/header.jpg`;
export const headerMobile = `${IMAGE_URL}/headerMobile.jpg`;
export const logo = `${IMAGE_URL}/logo.png`;
export const logoText = `${IMAGE_URL}/logoText.png`;
export const shopInsta = `${IMAGE_URL}/shopInsta.jpg`;
export const shopInstaMobile = `${IMAGE_URL}/shopInstaMobile.jpg`;

export const getProductImg = id => `${PRODUCT_IMAGE_URL}/${id}.jpg`;
