import React, { useContext } from 'react';
import classes from './checkout.module.scss';
import { useFormik } from 'formik';
import { checkoutSchema } from '../../functions/checkoutSchema';
import TextInput from '../../components/UI/TextInput/TextInput';
import Button from '../../components/UI/Button/Button';
import { Forms } from '../../components/Forms/Forms';
import { API_URL } from '../../config/apiendpoint';
import { Context } from '../../Context';
import axios from 'axios';
import { getAuth } from '../../functions/getAuth';

export const Checkout = () => {
  const { state } = useContext(Context);
  const { cart, paynow, disablePayNow } = state;
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      streetAddress: '',
      city: '',
      postcode: '',
      state: '',
      country: 'AU',
    },
    validationSchema: checkoutSchema,
    onSubmit: async values => {
      const jwtToken = getAuth();
      if (jwtToken !== null) {
        try {
          disablePayNow();
          const response = await axios.post(`${API_URL}/checkout/payment`, {
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              address: {
                line1: values.streetAddress,
                city: values.city,
                country_code: values.country,
                postal_code: values.postcode,
                state: values.state,
              },
            },
            items: cart,
          });
          if (response.data.link) window.location.href = response.data.link;
        } catch (error) {
          console.log(error.message);
        }
      } else {
        console.log('cancelled');
      }
    },
  });

  return (
    <div className={classes.container}>
      <Forms title="Checkout">
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            type="text"
            text="first name"
            id="firstName"
            {...formik.getFieldProps('firstName')}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className={classes.error}>{formik.errors.firstName}</div>
          ) : null}
          <TextInput
            type="text"
            text="last name"
            id="lastName"
            {...formik.getFieldProps('lastName')}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className={classes.error}>{formik.errors.lastName}</div>
          ) : null}
          <TextInput
            type="email"
            text="email address"
            id="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={classes.error}>{formik.errors.email}</div>
          ) : null}
          <TextInput
            type="text"
            text="mobile number"
            id="phone"
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className={classes.error}>{formik.errors.phone}</div>
          ) : null}
          <TextInput
            type="text"
            text="street address"
            id="streetAddress"
            {...formik.getFieldProps('streetAddress')}
          />
          {formik.touched.streetAddress && formik.errors.streetAddress ? (
            <div className={classes.error}>{formik.errors.streetAddress}</div>
          ) : null}
          <TextInput
            type="text"
            text="city"
            id="city"
            {...formik.getFieldProps('city')}
          />
          {formik.touched.city && formik.errors.city ? (
            <div className={classes.error}>{formik.errors.city}</div>
          ) : null}
          <TextInput
            type="text"
            text="postcode"
            id="postcode"
            {...formik.getFieldProps('postcode')}
          />
          {formik.touched.postcode && formik.errors.postcode ? (
            <div className={classes.error}>{formik.errors.postcode}</div>
          ) : null}
          <TextInput
            type="text"
            text="state"
            id="state"
            {...formik.getFieldProps('state')}
          />
          {formik.touched.state && formik.errors.state ? (
            <div className={classes.error}>{formik.errors.state}</div>
          ) : null}
          <TextInput
            type="text"
            text="country"
            id="country"
            name="country"
            disabled
            value={formik.values.country}
          />
          <div className={classes.btn}>
            <Button
              type="submit"
              text="Pay now (by PayPal)"
              disabled={paynow}
            />
          </div>
        </form>
      </Forms>
    </div>
  );
};
