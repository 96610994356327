import React from 'react';
import classes from './contactThanks.module.scss';

export const ContactThanks = () => {
  return (
    <div className={classes.container}>
      <h1>Thanks for contacting us.</h1>
      <h2>We will respond to your query as soon as possible.</h2>
    </div>
  );
};
