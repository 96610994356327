import * as Yup from 'yup';

export const checkoutSchema = Yup.object({
  firstName: Yup.string()
    .max(32, 'Must be 32 characters or less')
    .matches(/^[A-Za-z ]+$/, {
      message: 'Only contains Letters',
    })
    .required('Required'),
  lastName: Yup.string()
    .max(32, 'Must be 32 characters or less')
    .matches(/^[A-Za-z ]+$/, {
      message: 'Only contains Letters',
    })
    .required('Required'),
  email: Yup.string()
    .email('Invalid email addresss')
    .required('Required'),
  phone: Yup.string().matches(/^[0-9 +-]+$/, {
    message: 'Only contains numbers',
  }),
  streetAddress: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  postcode: Yup.string()
    .matches(/^[0-9]+$/, 'Must be numbers only')
    .min(4, 'Invalid Postcode')
    .max(4, 'Invalid Postcode')
    .required('Required'),
  state: Yup.string().required('Required'),
});
