import React, { useContext } from 'react';
import classes from './products.module.scss';
import Product from './Product/Product';
import { Context } from '../../Context';

const Products = () => {
  const { state } = useContext(Context);
  const { products, addToCart, addToWishlist } = state;

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {products
          ? products.map(item => (
              <Product
                key={item.id}
                id={item.id}
                name={item.name}
                price={item.price}
                addToCart={addToCart}
                addToWishlist={addToWishlist}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Products;
