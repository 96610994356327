import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Footer from './components/UI/Footer/Footer';
import Home from './containers/Home/Home';
import ShopNow from './containers/ShopNow/ShopNow';
import AboutUs from './containers/AboutUs/AboutUs';
import Contact from './containers/Contact/Contact';
import Cart from './containers/Cart/Cart';
import { Provider } from './Context';
import Modal from './components/UI/Modal/Modal';
import Product from './containers/Product/Product';
import Wishlist from './containers/Wishlist/Wishlist';
import { Checkout } from './containers/Checkout/Checkout';
import { Login } from './containers/Login/Login';
import { Register } from './containers/Register/Register';
import MyAccount from './containers/MyAccount/MyAccount';
import ScrollUp from './components/UI/ScrollUp/ScrollUp';
import Faqs from './containers/Footer/FAQs/Faqs';
import ShippingReturns from './containers/Footer/ShippingReturns/ShippingReturns';
import TermPolicies from './containers/Footer/TermsPolicies/TermsPolicies';
import Navigation from './containers/Navigation/Navigation';
import { OrderStatus } from './containers/OrderStatus/OrderStatus';
import { ContactThanks } from './containers/ContactThanks/ContactThanks';
import { PrivateRoute } from './routes/PrivateRoute';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Logout from './containers/Logout/Logout';
import { Order } from './containers/Order/Order';

function App() {
  return (
    <div className="App">
      <Provider>
        <Modal />
        <Navigation />
        <Switch>
          <Route path="/products/:id" component={Product} />
          <Route exact path="/products" component={ShopNow} />
          <Route path="/wishlist" component={Wishlist} />
          <PrivateRoute exact path="/order/:id" component={Order} />
          <PrivateRoute exact path="/my-account" component={MyAccount} />
          <Route path="/cart" component={Cart} />
          <Route path="/about-company" component={AboutUs} />
          <PrivateRoute exact path="/checkout" component={Checkout} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/shipping-returns" component={ShippingReturns} />
          <Route path="/terms-policies" component={TermPolicies} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/home" component={Home} />
          <ProtectedRoute path="/login" component={Login} />
          <ProtectedRoute path="/register" component={Register} />
          <Route
            exact
            path="/success"
            render={props => (
              <OrderStatus
                {...props}
                m1="Your order is successful."
                m2="Thanks for ordering with us."
              />
            )}
          />
          <Route
            exact
            path="/cancel"
            render={props => (
              <OrderStatus
                {...props}
                m1="Your order is not successful."
                m2="Payment was declined."
              />
            )}
          />
          <Route exact path="/contact/thanks" component={ContactThanks} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/logout" component={Logout} />
          <Redirect from="/" to="/home" />
        </Switch>
        <ScrollUp />
        <Footer />
      </Provider>
    </div>
  );
}

export default App;
