import React, { useContext, useEffect } from 'react';
import classes from './myAccount.module.scss';
import Title from '../../components/UI/Title/Title';
import Flower from '../../components/Branding/Flower/Flower';
import { Context } from '../../Context';
import { OrderList } from '../../components/OrderList/OrderList';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';

const MyAccount = () => {
  const { state } = useContext(Context);
  const { getOrders, orders, user } = state;

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <div className={classes.container}>
      <Title content="My Account" />
      <Flower />
      <div className={classes.personalInfo}>
        <div className={classes.title}>
          <div>Personal Info</div>
          <Link to="/logout">
            <Button text="Sign Out" />
          </Link>
        </div>
        <div className={classes.userdetails}>
          {user ? (
            <>
              <p className={classes.name}>
                Name : {`${user.firstName} ${user.lastName}`}
              </p>
              <p>Birthday : {user.birthday.split('T')[0]}</p>
              <p>Email : {user.email}</p>{' '}
            </>
          ) : null}
        </div>
      </div>
      <div className={classes.orderHistory}>
        <div>Order History</div>
        <div className={classes.title}>
          <p>Order Date</p>
          <p>Order Number</p>
          <p>Total Amount</p>
        </div>
        {orders
          ? orders.map(order => (
              <OrderList
                id={order._id}
                key={order._id}
                date={order.date.split('T')[0]}
                price={order.total}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default MyAccount;
