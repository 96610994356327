import * as Yup from "yup";


export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email addresss")
    .required("Required"),
  password: Yup.string()
    .min(8, "Must be between 8 - 32 characters")
    .max(32, "Must be between 8 - 32 characters")
    .required("Required")
});
