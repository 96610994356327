import React from 'react';
import { flowerLogo } from '../../../images/media';
import classes from './flower.module.scss';

const Flower = () => {
  return (
    <div className={classes.container}>
      <img src={flowerLogo} alt="flawless" />
    </div>
  );
};

export default Flower;
