import React, { useContext, useState } from 'react';
import classes from './register.module.scss';
import { useFormik } from 'formik';
import { registerSchema } from '../../functions/registerSchema';
import TextInput from '../../components/UI/TextInput/TextInput';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';
import { Forms } from '../../components/Forms/Forms';
import axios from 'axios';
import { API_URL } from '../../config/apiendpoint';
import { Context } from '../../Context';
import { Error } from '../../components/Error/Error';

export const Register = props => {
  const [error, setError] = useState(null);
  const { state } = useContext(Context);
  const { checkAuth } = state;
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      birthday: '',
      email: '',
      password: '',
    },
    validationSchema: registerSchema,
    onSubmit: async values => {
      console.log(values);
      try {
        const response = await axios.post(`${API_URL}/user/register`, {
          firstName: values.firstName,
          lastName: values.lastName,
          username: values.email,
          birthday: values.birthday,
          email: values.email,
          password: values.password,
        });
        localStorage.setItem(
          'flawless-token',
          response.headers['x-auth-token'],
        );
        checkAuth(response.data.user);
        props.history.push('/');
      } catch (error) {
        error.response
          ? setError(error.response.data.message)
          : setError(error.message);
        dismissError(5000);
      }
    },
  });

  const dismissError = async time => {
    await setTimeout(() => {
      setError(null);
    }, time);
  };

  return (
    <div className={classes.container}>
      <Forms title="register">
        <form onSubmit={formik.handleSubmit}>
          {error ? (
            <Error error={error} onClick={() => dismissError(0)} />
          ) : null}
          <TextInput
            type="text"
            text="first name"
            id="firstName"
            {...formik.getFieldProps('firstName')}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className={classes.error}>{formik.errors.firstName}</div>
          ) : null}
          <TextInput
            type="text"
            text="last name"
            id="lastName"
            {...formik.getFieldProps('lastName')}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className={classes.error}>{formik.errors.lastName}</div>
          ) : null}
          <TextInput
            type="date"
            text="birthday"
            id="birthday"
            min="1920-01-01"
            max="2006-01-01"
            {...formik.getFieldProps('birthday')}
          />
          {formik.touched.birthday && formik.errors.birthday ? (
            <div className={classes.error}>{formik.errors.birthday}</div>
          ) : null}
          <TextInput
            type="email"
            text="email"
            id="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={classes.error}>{formik.errors.email}</div>
          ) : null}
          <TextInput
            type="password"
            text="password"
            id="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className={classes.error}>{formik.errors.password}</div>
          ) : null}
          <div className={classes.btn}>
            <Button type="submit" text="Submit" />
            <div className={classes.signup}>
              Already registered? <Link to="/login">Log In here</Link>
            </div>
          </div>
        </form>
      </Forms>
    </div>
  );
};
