import React from 'react';
import classes from './quantity.module.scss';
import { FaMinus, FaPlus } from 'react-icons/fa';

const Quantity = ({ info, add, minus }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.quantity}>
        <span onClick={() => minus(info.id)}>
          <FaMinus />
        </span>
        <span>{info.quantity}</span>
        <span onClick={() => add(info.id)}>
          <FaPlus />
        </span>
      </div>
    </div>
  );
};

export default Quantity;
