import React from 'react';
import classes from './product.module.scss';
import formatPrice from '../../functions/formatPrice';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getProductImg } from '../../images/media';

const Product = ({ addToCart, info, removeWishlist }) => {
  return (
    <div className={classes.container}>
      <div className={classes.product}>
        <Link to={`/products/${info.id}`}>
          <div className={classes.thumbnail}>
            <img src={getProductImg(info.id)} alt="product" />
          </div>
          <div className={classes.name}>{info.name}</div>
        </Link>
      </div>
      <div className={classes.price}>{`AU $${formatPrice(info.price)}`}</div>
      <div className={classes.btn} onClick={() => addToCart(info.id)}>
        Add to Cart
      </div>
      <div className={classes.delete} onClick={() => removeWishlist(info.id)}>
        <FaTrash />
      </div>
    </div>
  );
};

export default Product;
