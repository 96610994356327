import React from 'react';
import classes from './navbar.module.scss';
import { NavLink } from 'react-router-dom';

const Bar = props => {
  return (
    <div className={classes.container}>
      <NavLink to='/home' activeClassName={classes.active}>
        <div className={classes.btn}>Home</div>
      </NavLink>
      <NavLink to='/products' activeClassName={classes.active}>
        <div className={classes.btn}>Shop Now </div>
      </NavLink>
      <NavLink to='/about-company' activeClassName={classes.active}>
        <div className={classes.btn}>About Us</div>
      </NavLink>
      <NavLink to='/contact' activeClassName={classes.active}>
        <div className={classes.btn}>Contact</div>
      </NavLink>
    </div>
  );
};

export default Bar;
