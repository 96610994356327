import React from 'react';
import classes from './faqs.module.scss';
import Title from '../../../components/UI/Title/Title';
import Flower from '../../../components/Branding/Flower/Flower';
import { Questions } from '../../../components/Questions/Questions';
import questionList from '../../../faqs.json';

const Faqs = () => {
  return (
    <div className={classes.container}>
      <Title content="Frequently asked questions" />
      <Flower />
      <main>
        {Object.keys(questionList).map((item, index) => (
          <Questions title={item} questions={questionList[item]} key={index} />
        ))}
      </main>
    </div>
  );
};

export default Faqs;
