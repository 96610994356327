import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { API_URL } from './config/apiendpoint';
import _ from 'lodash';
import { getAuth } from './functions/getAuth';

export const Context = React.createContext();

export class Provider extends Component {
  state = {
    products: null,
    cart: [],
    wishlist: [],
    price: {
      subtotal: 0,
      shipping: 0,
      taxes: 0,
      total: 0,
    },
    orderId: null,
    isModal: false,
    isProductModal: false,
    currentItem: [],
    isSidebar: false,
    login: null,
    currentProduct: null,
    orders: null,
    user: null,
    currentOrder: null,
    paynow: false,
    disablePayNow: () => {
      this.setState({ paynow: true });
    },
    getCurrentOrder: async id => {
      try {
        const response = await axios.get(`${API_URL}/user/orders/${id}`);
        this.setState({ currentOrder: response.data.order });
      } catch (error) {
        console.log(error.message);
      }
    },
    getOrders: async () => {
      try {
        const response = await axios.get(`${API_URL}/user/info`);
        this.setState({
          orders: response.data.info.orderList,
          user: response.data.info.user,
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    logout: () => {
      this.setState({ login: null });
    },
    getCurrentProduct: async id => {
      try {
        const response = await axios.get(`${API_URL}/items/${id}`);
        const { items } = response.data;
        let products = {
          name: items.name,
          id: items._id,
          description: items.description,
          price: items.price,
        };
        this.setState({
          currentProduct: products,
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    updateCart: async () => {
      const jwtToken = getAuth();
      if (jwtToken !== null) {
        try {
          const response = await axios.get(`${API_URL}/cart`);
          await this.setState({ cart: response.data.cart.items });
        } catch (error) {
          console.log(error.message);
        }
      } else {
        const cart = JSON.parse(localStorage.getItem('flawless-cart'));
        await this.setState({
          cart: cart ? cart : [],
        });
      }
      this.calculatePrice();
    },
    getProduct: async () => {
      try {
        const response = await axios.get(`${API_URL}/items`);
        const products = response.data.items.map(item => {
          return {
            name: item.name,
            id: item._id,
            description: item.description,
            price: item.price,
          };
        });
        this.setState({
          products,
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    setOrderId: id => {
      this.setState({ orderId: id });
    },
    checkLogin: () => {
      const { login } = this.state;
      if (login) {
        if (login.success) return true;
      }
      return false;
    },
    checkAuth: async () => {
      axios.defaults.headers.common['x-auth-token'] = getAuth();
      try {
        const user = jwtDecode(getAuth());
        await this.setState({
          login: {
            success: true,
            user: {
              firstName: user.firstName,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    removeModal: () => {
      this.setState({
        isModal: false,
        isProductModal: false,
      });
    },
    sidebarOpen: () => {
      this.setState({ isSidebar: true });
    },
    sidebarClose: () => {
      this.setState({ isSidebar: false });
    },
    addToWishlist: id => {
      const { wishlist, products } = this.state;
      const itemIndex = wishlist.findIndex(item => item.id === id);
      if (itemIndex === -1) {
        const index = products.findIndex(item => item.id === id);
        const item = {
          ...products[index],
        };
        wishlist.push(item);
        this.setState({ wishlist });
      }
    },
    removeWishlist: id => {
      const { wishlist } = this.state;
      const newWishlist = wishlist.filter(item => item.id !== id);
      this.setState({ wishlist: newWishlist });
    },
    addToCart: async id => {
      this.setState({ isModal: true, isProductModal: true });
      const { cart, products } = this.state;
      const itemIndex = cart.findIndex(item => item.id === id);
      if (itemIndex !== -1) {
        if (cart[itemIndex].quantity === 12) return;
        cart[itemIndex].quantity += 1;
        cart[itemIndex].totalPrice = this.calculateItemPrice(cart[itemIndex]);
        const currentItem = cart[itemIndex];
        await this.setState({ cart, currentItem });
      } else {
        const index = products.findIndex(item => item.id === id);
        const newProduct = _.pick(this.state.products[index], [
          'id',
          'name',
          'price',
        ]);
        const cartProduct = {
          ...newProduct,
          quantity: 1,
          totalPrice: products[index].price,
        };
        cart.push(cartProduct);
        const currentItem = products[index];
        await this.setState({ cart, currentItem });
      }
      this.calculatePrice();
      this.saveCart();
    },
    addQuantity: async id => {
      const { cart } = this.state;
      const index = this.state.cart.findIndex(item => item.id === id);
      if (cart[index].quantity === 12) return;
      cart[index].quantity += 1;
      cart[index].totalPrice = this.calculateItemPrice(cart[index]);
      await this.setState({ cart });
      this.calculatePrice();
      this.saveCart();
    },
    minusQuantity: async id => {
      const { cart } = this.state;
      const index = this.state.cart.findIndex(item => item.id === id);
      if (cart[index].quantity === 1) return;
      cart[index].quantity -= 1;
      cart[index].totalPrice = this.calculateItemPrice(cart[index]);
      await this.setState({ cart });
      this.calculatePrice();
      this.saveCart();
    },
    removeProduct: async id => {
      const { cart } = this.state;
      const unit = cart.filter(item => item.id !== id);
      await this.setState({ cart: unit });
      this.calculatePrice();
      this.saveCart();
    },
    updateContent: () => {
      this.state.checkAuth();
      this.state.updateCart();
    },
  };
  calculateItemPrice = item => {
    const totalPrice = item.price * item.quantity;
    return +totalPrice.toFixed(2);
  };
  calculatePrice = () => {
    const { ...price } = this.state.price;
    const [...cart] = this.state.cart;

    let subtotal = 0;
    let i;
    for (i in cart) {
      subtotal += cart[i].totalPrice;
    }

    // price.taxes = (10 * price.subtotal) / 100;
    // price.shipping = cart.length > 0 ? 10 : 0;
    price.shipping = 0;
    price.subtotal = +subtotal.toFixed(2);
    price.taxes = 0;
    price.total = price.subtotal + price.taxes + price.shipping;

    price.total = +price.total.toFixed(2);

    this.setState({ price });
  };

  saveCart = async () => {
    const jwtToken = getAuth();
    if (jwtToken !== null) {
      try {
        const response = await axios.post(`${API_URL}/cart`, {
          cart: this.state.cart,
        });
        console.log(response);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      localStorage.setItem('flawless-cart', JSON.stringify(this.state.cart));
    }
  };

  componentDidMount() {
    this.state.getProduct();
    this.state.updateContent();
  }

  render() {
    return (
      <Context.Provider value={{ state: this.state }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
