import React from "react";
import classes from "./cartProduct.module.scss";
import Product from "./Name/Name";
import Price from "./Price/Price";
import Quantity from "./Quantity/Quantity";
import Remove from "./Remove/Remove";

const CartProduct = ({ info, add, minus, remove }) => {
  return (
    <div className={classes.wrapper}>
      <Product info={info} />
      <Price price={info.price} />
      <Quantity add={add} minus={minus} remove={remove} info={info} />
      <Remove remove={remove} info={info} />
      <Price price={info.totalPrice} />
    </div>
  );
};

export default CartProduct;
