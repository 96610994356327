import React from 'react';
import classes from './header.module.scss';

const Header = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.product}>Product</div>
      <div className={classes.price}>Price</div>
      <div className={classes.quantity}>Quantity</div>
      <div className={classes.remove}></div>
      <div className={classes.price}>Total</div>
    </div>
  );
};

export default Header;
