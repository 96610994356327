import React, { Fragment, useContext } from 'react';
import Button from '../../Button/Button';
import { Link } from 'react-router-dom';
import { Context } from '../../../../Context';
import classes from './Product.module.scss';
import formatPrice from '../../../../functions/formatPrice';
import { getProductImg } from '../../../../images/media';

const Product = () => {
  const { state } = useContext(Context);
  const { removeModal, currentItem } = state;
  return (
    <Fragment>
      <div className={classes.title}>Item added to Cart</div>
      <div className={classes.product}>
        <img src={getProductImg(currentItem.id)} alt="product" />
      </div>
      <div className={classes.name}>{currentItem.name}</div>
      <div className={classes.price}>
        Price : AU ${formatPrice(currentItem.price)}
      </div>
      <div className={classes.btnContainer}>
        <Button text="Continue Shopping" onClick={removeModal} />
        <Link to="/cart">
          <Button text="Go To Cart" onClick={removeModal} />
        </Link>
      </div>
    </Fragment>
  );
};

export default Product;
