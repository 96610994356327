import React from 'react';
import classes from './header.module.scss';
import Logo from '../../Branding/Logo/Logo';
import { Link } from 'react-router-dom';
import {
  FaFacebookSquare,
  FaInstagram,
  FaUser,
  FaHeart,
  FaShoppingBag,
} from 'react-icons/fa';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import Instagram from '../../Branding/Social/Instagram';
import Facebook from '../../Branding/Social/Facebook';
import BurgerIcon from '../../BurgerIcon/BurgerIcon';

const Header = ({ login, cart }) => {
  let position = useWindowScrollPosition();

  return (
    <div className={position.y > 0 ? classes.textLogo : classes.container}>
      <div className={classes.menu}>
        <BurgerIcon />
      </div>
      <div className={classes.socialIcons}>
        <Facebook>
          <FaFacebookSquare className={classes.sIcon} />
        </Facebook>
        <Instagram>
          <FaInstagram className={classes.sIcon} />
        </Instagram>
      </div>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.icons}>
        <div className={classes.icon}>
          <Link to="/login">
            <FaUser />
            <p>{login ? login.user.firstName : 'Login'}</p>
          </Link>
        </div>
        <div className={classes.icon}>
          <Link to="/wishlist">
            <FaHeart />
            <p>Wishlist</p>
          </Link>
        </div>
        <div className={classes.cartIcon}>
          <Link to="/cart">
            <FaShoppingBag />
            {cart.length > 0 ? (
              <div className={classes.cartCount}> {cart.length} </div>
            ) : null}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
